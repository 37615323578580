import React, { useEffect, useState } from 'react';
import { Avatar, Button, List, Skeleton } from 'antd';
import useGetPrivateData from '../../../../hooks/useGetPrivateData';

const JobOfferTable = () => {
    const [data, setdata] = useState()
    const { getData, loading } = useGetPrivateData()
    async function getApplied(params) {
        await getData("offers", (d) => setdata(d))
    }
    useEffect(() => {
        getApplied()
    }, [])

    return (
        <List
            itemLayout="horizontal"
            className='w-100 px-5 card'
            dataSource={data}
            renderItem={(item) => (
                <List.Item
                    actions={[<div className=''>
                        <Button onClick={() => window.location.href = "/offer/" + item.token} className='bg-primary mr-5 text-white border border-primary px-auto' key="list-loadmore-edit">accept</Button>
                        {item.link && <a className='text-dark' href={item.link} key="list-loadmore-more">view</a>}
                    </div>]}
                >
                    <List.Item.Meta
                        // avatar={<Avatar src={item.picture.large} />}
                        title={item.title}
                        description={item.company}
                    />
                </List.Item>
            )}
        />
    );
};
export default JobOfferTable;