import React, { useEffect, useState } from 'react';
import { Collapse, Card, List, Typography } from 'antd';
import useGetPrivateData from '../../../../hooks/useGetPrivateData';
import usePostPrivate from '../../../../hooks/usePostPrivate';
import { truncateEmail } from '../../../../util';
import NoDataCard from '../../../../components/comps/nodata';

const { Panel } = Collapse;
const { Title, Text } = Typography;

const PaymentCard = () => {
    const [data, setdata] = useState()
    const { getData } = useGetPrivateData()
    const { postData } = usePostPrivate()

    async function fetchData(params) {
        await getData("admin/payment", (d) => setdata(d.reverse()))
    }
    async function updatePayment(id) {
        await postData("admin/payment?id=" + id, {}, async (d) => await fetchData())
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            {data && data?.length > 0 ? <Collapse size='large' className='my-4  border-0 p-0 w-100' accordion>
                {data.map((payment, index) => (
                    <Panel
                        header={
                            <div className='d-flex justify-content-between '>
                                <Text strong>{truncateEmail(payment.email)}</Text>
                                <Text className='mx-5' strong>{payment.status}</Text>
                            </div>
                        }
                        key={index}
                    >
                        <Card bordered={false}>
                            <List>
                                <List.Item>
                                    <Text strong>Email:</Text> {payment.email}
                                </List.Item>
                                <List.Item>
                                    <Text strong>Date:</Text> {payment.dateString}
                                </List.Item>
                                <List.Item>
                                    <Text strong>Reason:</Text> {payment.reason}
                                </List.Item>
                                <List.Item>
                                    <Text strong>Amount:</Text> {payment.amount} {payment.cryptoName}
                                </List.Item>
                                <List.Item>
                                    <Text strong>Status:</Text> {payment.status}
                                </List.Item>
                            </List>
                            <button onClick={() => {
                                updatePayment(payment._id)
                            }} className='btn btn-primary mt-7'>{"Confirm"}</button>
                        </Card>
                    </Panel>
                ))}
            </Collapse > : data && <NoDataCard isBorder={false} />}

        </>
    );
};

export default PaymentCard;
