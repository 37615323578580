import React, { useEffect, useState } from 'react';
import { Modal, Button, Image } from 'antd';
import useGetPublicData from '../../../../hooks/useGetPublicData';

const ImagePreviewModal = ({ isModalVisible, handleCancel = () => null, }) => {
    const { getPublicData } = useGetPublicData()
    const [data, setdata] = useState()

    async function fetchData(params) {
        await getPublicData("app?includes=preview_licence", (d) => setdata(d))
        
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div>
            <Modal
                open={isModalVisible}
                footer={null}
                onCancel={handleCancel}
                centered
            >
                <Image
                    width="100%"
                    src={data?.preview_licence}
                    alt="Preview"
                />
            </Modal>
        </div>
    );
};

export default ImagePreviewModal;
