import React, { useEffect, useState } from 'react'
import Preloader from '../../../components/comps/preloader'
import NoDataCard from '../../../components/comps/nodata'
import useGetPrivateData from '../../../hooks/useGetPrivateData'
import JobOfferTable from './comps/offecardlist'


export default function Offerlist() {
  const [data, setdata] = useState()
  const { getData, loading } = useGetPrivateData()
  async function getApplied(params) {
    await getData("jobs?applied=" + "applied", (d) => setdata(d.reverse()))
  }
  useEffect(() => {
    getApplied()
  }, [])

  return (
    <div class="col-12 col-xl-10 col-lg-10 mt-14">
      <Preloader open={loading || !data} />
      <div class="mb-5">
        <h4 class="font-size-7 mb-9 text-center">Your Offer</h4>
        <div class="row justify-content-center py-3 px-10">
          <JobOfferTable />
        </div>
      </div>
    </div>
  )
}
