import React, { useEffect, useState } from 'react';
import { Form, Input, Upload, Button, Radio, Typography, Alert, Row, Col } from 'antd';
import { ClockCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, FileTextOutlined, UploadOutlined } from '@ant-design/icons';
import LicenseInfoModal from './comps/infomodal';
import AlertComp from '../../../components/comps/alert';
import { formatTimeRemaining } from '../../../util';
import usePostPrivate from '../../../hooks/usePostPrivate';
import usePostFormPrivate from '../../../hooks/usePostForm';
import { Context } from '../../../AppContext';
import ImagePreviewModal from './comps/previewlicense';
import useGetPublicData from '../../../hooks/useGetPublicData';
const { Text, Link } = Typography;

const JobAccept = ({ company, token, data, time, rejectOffer = () => null, getOffer = async () => null }) => {
    const [hasUKLicense, setHasUKLicense] = useState(true);
    const [companyAssist, setCompanyAssist] = useState("option2");
    const [showLicenseInfo, setShowLicenseInfo] = useState(false);
    const [license, setLicense] = useState([]);
    const [passport, setPassport] = useState([]);
    const [passportPhoto, setPassportPhoto] = useState([]);
    const [qualification, setQualification] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [amount, setdata] = useState()
    useEffect(() => {
        if (!hasUKLicense && companyAssist === "option2") {
            setLicense([])
        }
    }, [hasUKLicense, companyAssist])



    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const { postFormData } = usePostFormPrivate()
    const { postData } = usePostPrivate()
    const { user, setFeedback } = Context()
    const handleLicenseChange = (e) => {
        setHasUKLicense(e.target.value === 'yes');
    };
    const handleAssistChange = (e) => {
        setCompanyAssist(e.target.value);
    };
    async function uploadDocument(data, condition, callback = () => null) {
        const formData = new FormData();
        data.map(file => formData.append(condition, file.originFileObj))
        formData.append('email', user?.email)
        formData.append('token', token);
        formData.append('condition', condition);
        formData.append('assist', companyAssist === "option2" && !hasUKLicense && license.length == 0 ? true : false);
        await postFormData("upload/documents", formData, async () => { await fetchData(); })
        callback()
        setFeedback()
    }

    const handleSubmit = async (offer) => {
        const isAssisted = companyAssist === "option2" &&
            !hasUKLicense && license.length == 0 ? true : false
        const data = { token, email: user?.email, assist: isAssisted, offer: "accept" }

        await postData("offer/update", data, async () => window.location.reload())

    };

    const { getPublicData } = useGetPublicData()

    async function fetchData(params) {
        await getPublicData("app?includes=license_fee", (d) => setdata(d))

    }
    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        if (!hasUKLicense) {
            setLicense([])
        }
    }, [hasUKLicense])
    useEffect(() => {
        const handleVisibilityChange = async () => {
            if (document.visibilityState === 'visible') {
                // Function to run when the user comes back to the tab
                await getOffer()
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    async function requestAssist(params) {
        await postData("offer/request", { token }, () => window.location.reload())
    }

    return (


        <>
            <Form
                layout="vertical"
                style={{ margin: '0 10' }}
                className='px-5 w-100 py-10'
            >
                {time && <Alert
                    description={
                        <>
                            <p style={{ fontSize: 14 }}>
                                <ClockCircleOutlined /> It's remaining <strong>{formatTimeRemaining(time)}</strong> to terminate the offer.
                            </p>
                            <p style={{ fontSize: 14 }}>
                                You've requested assistance from <strong>{company}</strong> to enroll you in acquiring the driving license.
                            </p>
                            <p style={{ fontSize: 14 }}>
                                <a href={`/user/payment?amount=${amount?.license_fee}&reason=License`} target='_blank' style={{ color: '#ff4d4f', textDecoration: 'underline' }}>
                                    Click here to complete the payment process
                                </a>
                            </p>
                            <p style={{ fontSize: 14 }}>
                                <FileTextOutlined /> If you've obtained a valid license, you can upload it.
                            </p>
                            <p style={{ fontSize: 14 }}>
                                <CloseCircleOutlined /> If you no longer wish to proceed, you can reject offer.
                            </p>
                        </>
                    }
                    type="error"
                    showIcon
                    style={{ marginBottom: '20px' }}
                />}

                <div className="my-5"><AlertComp /></div>
                {/* UK Driving License Upload Section */}
                {<Form.Item className='w-100' label={"1. Upload a valid UK Driving License"}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item className='w-100' name="license" disabled={!hasUKLicense && companyAssist === "option2"} valuePropName="fileList" getValueFromEvent={(e) => e.fileList} noStyle>
                                <Upload maxCount={4} fileList={license} disabled={!hasUKLicense && companyAssist === "option2"} onChange={(e) => { setLicense(e.fileList) }} listType="picture-card" beforeUpload={() => false} accept="image/*">
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Form.Item>
                            {license.length > 0 && data?.offer !== "accept" && <Button onClick={() =>
                                uploadDocument(license, "license")} className='btn-primary my-5'> Save License</Button>}
                        </Col>
                    </Row>
                </Form.Item>}
                {/* No License Option */}
                <Form.Item label="Have you uploaded a valid UK Driving License?">
                    <Radio.Group onChange={handleLicenseChange} defaultValue="yes">
                        <Radio value="yes">Yes! I have</Radio>
                        <Radio disabled={data?.assist || data?.hasUserPaidForLicense} className='mt-3' value="no">No, I don't have a valid UK Driving License</Radio>
                    </Radio.Group>
                </Form.Item>

                {/* Options for Users Without UK Driving License */}
                {!hasUKLicense && (
                    <>
                        <Form.Item label="If not, choose one of the following options:">
                            <Radio.Group defaultValue={"option2"} onChange={handleAssistChange}>
                                <Radio disabled={data?.assist} value="option1">Go to UK DVLA Official Website{" "}
                                    <a target='_blank' href="https://www.gov.uk/browse/driving/driving-licences" > here.</a></Radio>
                                <Radio className='mt-5' disabled={data?.assist} value="option2">Ask <strong>{company}</strong> to assist you in enrolling for UK driving license.</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item>
                            <Link href="#" className='text-primary' onClick={() => setShowLicenseInfo(true)}>
                                Click here to see the process of acquiring a UK driving license through {company}
                            </Link>
                        </Form.Item>
                    </>
                )}

                {/* Passport Upload Section */}
                {((!data?.assist) || (data?.offer === "nil")) && <Row gutter={16}>
                    <Col span={12}><Form.Item label={`2. ${data?.hasUploadedPassport ? "Int'l passport image has been uploaded." : "Upload International Passport Image"}`}>
                        {!data?.hasUploadedPassport && <Form.Item name="passportImage" valuePropName="fileList" getValueFromEvent={(e) => e.fileList} noStyle>
                            <Upload disabled={data?.hasUploadedPassport} onChange={(e) => setPassport(e.fileList)} fileList={passport} maxCount={3} listType="picture-card" beforeUpload={() => false} accept="image/*">
                                <Button icon={<UploadOutlined />}>Upload Int'l Passport</Button>
                            </Upload>
                        </Form.Item>}
                        {passport.length > 0 && !data?.hasUploadedPassport && <Button onClick={() => uploadDocument(passport, "passport", () => setPassport([]))} className='btn-primary my-5'> Save Int'l Passport</Button>}
                    </Form.Item></Col></Row>}
                {((!data?.assist) || (data?.offer === "nil")) && <Row gutter={16}>
                    <Col span={12}> <Form.Item label={data?.hasUploadedPassportPhoto ? "3. Passport Photograph has been uploaded" : "3. Upload Passport Photograph Image"}>
                        {!data?.hasUploadedPassportPhoto && <Form.Item name="passportPhoto" valuePropName="fileList" getValueFromEvent={(e) => e.fileList} noStyle>
                            <Upload disabled={data?.hasUploadedPassportPhoto} onChange={(e) => setPassportPhoto(e.fileList)} fileList={passportPhoto} maxCount={3} listType="picture-card" beforeUpload={() => false} accept="image/*">
                                <Button icon={<UploadOutlined />}>Upload Passport</Button>
                            </Upload>
                            {passportPhoto.length > 0 && !data?.hasUploadedPassportPhoto && <Button onClick={() => uploadDocument(passportPhoto, "passportPhoto", () => setPassportPhoto([]))} className='btn-primary my-5'> Save Passport Photo</Button>}
                        </Form.Item>}
                    </Form.Item></Col></Row>}

                {/* Academic Qualification Upload Section */}
                {((!data?.assist) || (data?.offer === "nil")) && <Row gutter={16}>
                    <Col span={12}><Form.Item label={data?.hasUploadedQualification ? "4. Academic qualification has been uploaded." : "4. Upload Academic Qualification Image"}>
                        {!data?.hasUploadedQualification && <Form.Item name="qualificationImage" valuePropName="fileList" getValueFromEvent={(e) => e.fileList} noStyle>
                            <Upload onChange={(e) => setQualification(e.fileList)} fileList={qualification} maxCount={5} listType="picture-card" beforeUpload={() => false} accept="image/*">
                                <Button icon={<UploadOutlined />}>Upload Qualification</Button>
                            </Upload>
                        </Form.Item>}
                        {qualification.length > 0 && <Button onClick={() => uploadDocument(qualification, "qualification", () => setQualification([]))} className='btn-primary my-5'> Save Qualification</Button>}
                    </Form.Item></Col></Row>}

                <Form.Item>
                    <button onClick={() => (data?.hasUserPaidForLicense ||
                        data?.offerStatus === "declined" || data?.offer === "accept")
                        ? (companyAssist === "option2" && !hasUKLicense && data?.hasUploadedPassport
                            && data?.hasUploadedPassportPhoto && data?.hasUploadedQualification) ? requestAssist() : uploadDocument(license, "license", () => window.location.reload()) : handleSubmit()}
                        disabled={(((license.length === 0 && hasUKLicense)
                            || !data?.hasUploadedPassport
                            || !data?.hasUploadedPassportPhoto || !data?.hasUploadedQualification)
                            && data?.offer === "nil") ||
                            (license.length === 0 && data?.offerStatus === "declined") ||
                            (license.length === 0 && data?.hasUserPaidForLicense) ||
                            (license.length === 0 && time && data?.assist)
                        }
                        className='btn btn-primary' type="submit" style={{ backgroundColor: '#008a5b', borderColor: '#008a5b', marginRight: '8px' }}>
                        {(data?.offer === "accept") ? (companyAssist === "option2" && !hasUKLicense && data?.hasUploadedPassport
                            && data?.hasUploadedPassportPhoto && data?.hasUploadedQualification) ? "Request License" : "Upload License" : "Accept Offer"}
                    </button>
                    <button onClick={rejectOffer} disabled={data?.offer === "reject" && !time} className='btn btn-danger'>
                        {data?.offer === "reject" ? "Offer Rejected" : "Reject Offer"}
                    </button>
                </Form.Item>
                <div className="my-5"><AlertComp /></div>

            </Form>
            <LicenseInfoModal handleOpen={showModal} isModalVisible={showLicenseInfo} company={company} handleOk={() => setShowLicenseInfo(false)} />
            <ImagePreviewModal handleCancel={handleCancel}
                isModalVisible={isModalVisible} />
        </>
    );
};

export default JobAccept;
