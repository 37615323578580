import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import Detail from './comp/detail'
import useGetPublicData from './../../hooks/useGetPublicData';
import Jobcard from './comp/jobcard';
import { Context } from '../../AppContext';
import ApplyJob from '../../components/comps/applyJob';
import { getRandomNumber } from '../../util';
import Preloader from '../../components/comps/preloader';
import NoDataCard from '../../components/comps/nodata';
import Congrats from '../../components/comps/congrats';

export default function Joblist() {
    const [page, setpage] = useState(getRandomNumber(1, 20))
    const [data, setdata] = useState()
    const [active, setActive] = useState(0)
    const [hasMore, sethasMore] = useState(true)
    const [open, setopen] = useState(false)
    const [msg, setMsg] = useState("")
    const [word, setWord] = useState("")

    const { getPublicData, loading } = useGetPublicData()
    const { screenWidth, user } = Context()
    async function fetchData(params) {
        await getPublicData("job?page=" + page, (d) => {
            const dbData = data ? [...data, ...d.data] : d.data
            setdata(dbData)
            sethasMore(d.hasMore)
            setMsg("")
        })
    }
    async function searchData(params) {
        await getPublicData("search?word=" + word, (d) => {
            setdata(d.data)
            setMsg(d.msg)
            sethasMore(false)
        })
    }
    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        const mod = document.getElementsByClassName("ant-modal-content")
        if (mod && open) {
            mod[0]?.classList.add("px-0")
        }
    }, [open])
    return (
        <>
            <Congrats />
            <Preloader open={loading || !data} />
            <div class="bg-black-2 mt-15 mt-lg-22 pt-18 pt-lg-13 pb-13">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <form onSubmit={(e) => { e.preventDefault(); searchData() }} class="search-form">
                                <div class="filter-search-form-2 bg-white rounded-sm shadow-7 pr-6 py-7 pl-6  search-1-adjustment">
                                    <div class="filter-inputs">
                                        <div class="form-group position-relative w-xl-50">
                                            <input required onChange={(e) => setWord(e.target.value)} class="form-control focus-reset pl-13" type="text" id="keyword" placeholder="Job title, Company name or Location" />
                                            <span class="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6"><i
                                                class="icon icon-zoom-2 text-primary font-weight-bold"></i></span>
                                        </div>
                                    </div>
                                    <div class="button-block">
                                        <button type='submit' class="btn btn-primary btn-medium line-height-reset h-100 btn-submit w-100 text-uppercase">Search</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="bg-default-1 pt-9 pb-13 pb-xl-30 pb-13 position-relative overflow-hidden"
            >
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-12">
                            {msg && <h6 class="text-center mb-6">{msg}</h6>}
                        </div>
                    </div>
                    <div class="row justify-content-center position-static">
                        <div class="col-12 col-xxl-8 col-xl-7 col-lg-10">
                            {data && data?.length > 0 ? <div class="Left">
                                <div
                                    class="justify-content-center search-nav-tab nav nav-tabs border-bottom-0"
                                    id="search-nav-tab"
                                    role="tablist"
                                >
                                    {(user?.applied && user.applied.length > 0 ? data.filter(item => !user.applied.includes(item._id)) : data)?.map((item, index) =>
                                        <Jobcard key={item._id}
                                            active={active}
                                            setActive={(i) => { setActive(i); setopen(true) }}
                                            data={{ ...item, index }} />)}
                                </div>
                                {hasMore && <div class="text-center pt-5 pt-lg-13">
                                    <a
                                        onClick={(e) => { e.preventDefault(); setpage(page + 1); fetchData() }}
                                        class="text-green font-weight-bold text-uppercase font-size-3 d-flex align-items-center justify-content-center"
                                        href="#"
                                    >Load More <i class="fas fa-sort-down ml-3 mt-n2 font-size-4"></i>
                                    </a>
                                </div>}
                            </div> : data && <NoDataCard />}
                        </div>
                        {data?.length > 0 && screenWidth >= 1200 && <Detail data={data[active]} />}
                    </div>
                </div>
            </div>
            {screenWidth < 1200 && <Modal
                style={{ top: 20, }}
                open={open}
                onCancel={() => setopen(false)}
                footer={() => <ApplyJob id={data[active]._id} />}
            >
                {data?.length > 0 && <Detail data={data[active]} />}
            </Modal>}
        </>

    )
}
