import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { Context } from '../../AppContext';
import useGetPrivateData from '../../hooks/useGetPrivateData';

const Congrats = () => {
    const [data, setData] = useState();
    const { width, height } = useWindowSize(); // Get window dimensions for Confetti
    const { offerTime } = Context()
    const { getData } = useGetPrivateData()
    async function getOfferData(params) {
        await getData("offer/check", (d) => setData(d))
    }
    useEffect(() => {
        const isShown = sessionStorage.getItem("showCongrats")
        if (!isShown) {
            getOfferData()
        }

    }, [])



    const handleOk = () => {
        sessionStorage.setItem("showCongrats", true)
        window.location.href = "/offer/" + data?.token

    };

    const handleCancel = () => {
        sessionStorage.setItem("showCongrats", true)
        setData()

    };



    return (
        <>
            {data && !offerTime && <Confetti style={{ position: 'fixed', top: 0, left: 0, zIndex: 2000 }}
                width={width} height={height} numberOfPieces={300} />}
            <Modal title="New Job Offer" open={data && !offerTime} onCancel={handleCancel} footer={null}>
                <p style={{ fontSize: 14, textAlign: "center" }}>🎉 Congrats! You've been offered the position of <strong>{data?.title}</strong> at <strong>{data?.company}</strong>.</p>
                <Button onClick={handleOk} type="primary" style={{ backgroundColor: '#008a5b', borderColor: '#008a5b' }}>
                    View details
                </Button>
            </Modal>
        </>
    );
};

export default Congrats;
