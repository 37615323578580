import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Sidebar from './comp/sidebar'
import UserInfo from './comp/userInfo'
import Adminsidebar from './comp/adminsidebar'
import Congrats from '../../components/comps/congrats'
import { Context } from '../../AppContext'

export default function User() {
    const location = useLocation().pathname
    const { offerTime } = Context()
    return (
        <>
            <Congrats />
            {location.includes("admin") ? <Adminsidebar /> : <Sidebar />}
            <div class="dashboard-main-container d-flex flex-column align-items-center bg-default-1 pt-28 pt-lg-27 pb-xl-25 pb-12" id="dashboard-body">
                {!location.includes("admin") && <UserInfo />}
                <Outlet />
            </div>
        </>
    )
}
