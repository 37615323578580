import React, { useEffect, useState } from 'react';
import { Form, Input, Button, DatePicker, Radio, Select } from 'antd';
import useGetPublicData from '../../../hooks/useGetPublicData';

const { Option } = Select;

const ApplicationForm = ({ callback = () => null }) => {
    const [form] = Form.useForm();
    const [data, setdata] = useState([])
    const { getPublicData } = useGetPublicData()
    async function fetchContries(params) {
        await getPublicData("country", (d) => setdata(d))
    }
    useEffect(() => {
        fetchContries()
    }, [])

    const onFinish = (values) => {
        const formattedValues = {
            'First Name': values.firstName,
            'Middle Name': values.middleName || "",
            'Last Name': values.lastName,
            'Date of Birth': values.dateOfBirth ? values.dateOfBirth.format('DD-MM-YYYY') : undefined,
            'Gender': values.gender,
            'Phone Number': values.phoneNumber,
            'Email Address': values.emailAddress,
            'Home Address': values.homeAddress,
            'Nationality': values.nationality,
            'National Identification Number': values.nationalIdNumber,
            'Passport Number': values.passportNumber,
            'Do you have a visa to work in the UK?': values.visaStatus,
            'Visa Type': values.visaType,
            'Visa Expiry Date': values.visaExpiryDate ? values.visaExpiryDate.format('DD-MM-YYYY') : undefined,
            'Do you have a UK driver\'s license?': values.ukDriversLicense,
            'Driver\'s License Number': values.driversLicenseNumber,
            'Driver\'s License Expiry Date': values.driversLicenseExpiryDate ? values.driversLicenseExpiryDate.format('DD-MM-YYYY') : undefined,
            'Years of Driving Experience': values.drivingExperience,
            'Types of Vehicles Driven': values.vehicleTypes,
            'Have you been convicted of any driving offences in the past five years?': values.drivingOffences,
            'Offence Details': values.offenceDetails,
            'Do you have any medical conditions that might affect your ability to drive?': values.medicalConditions,
            'Medical Condition Details': values.conditionDetails,
            'Have you had a medical examination in the past year?': values.medicalExamination,
            'Medical Examination Details': values.examinationDetails,
            'Highest Level of Education': values.educationLevel,
            'Have you completed any formal driving training or certification programs?': values.drivingTraining,
            'How did you hear about this job?': values.jobSource,
        };
        callback(formattedValues);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            size="large"
        >
            {/* Personal Information */}
            <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'Please enter your first name' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Middle Name" name="middleName">
                <Input />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'Please enter your last name' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Date of Birth" name="dateOfBirth" rules={[{ required: true, message: 'Please select your date of birth' }]}>
                <DatePicker style={{ width: '100%' }} format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item label="Gender" name="gender" rules={[{ required: true, message: 'Please select your gender' }]}>
                <Radio.Group>
                    <Radio value="male">Male</Radio>
                    <Radio value="female">Female</Radio>
                    <Radio value="prefer-not-to-say">Prefer not to say</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Phone Number" name="phoneNumber" rules={[{ required: true, message: 'Please enter your phone number' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Email Address (Ensure you use a correct email)." name="emailAddress" rules={[{ required: true, message: 'Please enter your email address' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Home Address" name="homeAddress" rules={[{ required: true, message: 'Please enter your home address' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="Nationality" name="nationality" rules={[{ required: true, message: 'Please select country' }]}>
                <Select>
                    {data.map(item => <Option key={item} value={item}>{item}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item label="National Identification Number" name="nationalIdNumber">
                <Input />
            </Form.Item>
            <Form.Item label="Passport Number" name="passportNumber">
                <Input />
            </Form.Item>
            <Form.Item label="Do you have a visa to work in the UK?" name="visaStatus" rules={[{ required: true, message: 'Please select your visa status' }]}>
                <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Visa Type" name="visaType">
                <Input />
            </Form.Item>
            <Form.Item label="Visa Expiry Date" name="visaExpiryDate">
                <DatePicker style={{ width: '100%' }} format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item label="Do you have a UK driver's license?" name="ukDriversLicense" rules={[{ required: true, message: 'Please select your driver\'s license status' }]}>
                <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Driver's License Number" name="driversLicenseNumber">
                <Input />
            </Form.Item>
            <Form.Item label="Driver's License Expiry Date" name="driversLicenseExpiryDate">
                <DatePicker style={{ width: '100%' }} format="DD-MM-YYYY" />
            </Form.Item>

            {/* Driving Experience */}
            <Form.Item label="Years of Driving Experience" name="drivingExperience" rules={[{ required: true, message: 'Please enter your driving experience' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Types of Vehicles Driven" name="vehicleTypes" rules={[{ required: true, message: 'Please enter the types of vehicles you have driven' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Have you been convicted of any driving offences in the past five years?" name="drivingOffences" rules={[{ required: true, message: 'Please select your offence history' }]}>
                <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Offence Details" name="offenceDetails">
                <Input.TextArea />
            </Form.Item>

            {/* Medical Information */}
            <Form.Item label="Do you have any medical conditions that might affect your ability to drive?" name="medicalConditions" rules={[{ required: true, message: 'Please select your medical condition status' }]}>
                <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Medical Condition Details" name="conditionDetails">
                <Input.TextArea />
            </Form.Item>
            <Form.Item label="Have you had a medical examination in the past year?" name="medicalExamination" rules={[{ required: true, message: 'Please select your medical examination status' }]}>
                <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Medical Examination Details" name="examinationDetails">
                <Input.TextArea />
            </Form.Item>

            {/* Education and Training */}
            <Form.Item label="Highest Level of Education" name="educationLevel" rules={[{ required: true, message: 'Please select your education level' }]}>
                <Select>
                    <Option value="high-school">High School</Option>
                    <Option value="college">College</Option>
                    <Option value="university">University</Option>
                </Select>
            </Form.Item>
            <Form.Item label="Have you completed any formal driving training or certification programs?" name="drivingTraining" rules={[{ required: true, message: 'Please select your driving training status' }]}>
                <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="How did you hear about this job?" name="jobSource" rules={[{ required: true, message: 'Please enter how you heard about this job' }]}>
                <Input />
            </Form.Item>

            <Form.Item>
                <Button className='btn-primary' htmlType="submit">Submit</Button>
            </Form.Item>
        </Form>
    );
};

export default ApplicationForm;
