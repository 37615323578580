import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useGetPublicData from '../../hooks/useGetPublicData'
import Error404 from '../error/error404'
import AlertComp from '../../components/comps/alert'
import ApplicationForm from './comp/applicationform'
import { Modal } from 'antd'
import Applicationpreview from './comp/applicationpreview'
import usePost from './../../hooks/usePost';
import { Context } from '../../AppContext'
import axios from 'axios'

export default function Jobapply() {
    const { id } = useParams()
    const [data, setdata] = useState({})
    const [form, setForm] = useState()
    const [open, setopen] = useState(false)
    const { getPublicData } = useGetPublicData()
    const { postData } = usePost()
    const { country } = Context()

    async function getData(params) {
        await getPublicData("job/" + id, (d) => setdata(d))
    }
    async function apply(params) {
        const result = await axios.get("https://ipapi.co/json")
        const { country_name, country_code } = result.data
        const data = { ...form, country_name, country_code }
        setopen(false)
        await postData("job/apply?id=" + id, data)
    }
    useEffect(() => {
        getData()
    }, [])
    useEffect(() => {
        const mod = document.getElementsByClassName("ant-modal-content")
        if (mod && open) {
            mod[0]?.classList.add("px-0")
        }
    }, [open])

    return (
        <>   {!data ? <Error404 /> : 
        <div class="jobDetails-section bg-default-1 pt-28 pt-lg-27 pb-xl-25 pb-12">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-10 col-lg-11 mt-4 ml-xxl-32 ml-xl-15 dark-mode-texts">
                        <div class="mb-9">
                            <a class="d-flex align-items-center ml-4" href="/">
                                <i
                                    class="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"
                                >
                                </i><span class="text-uppercase font-size-3 font-weight-bold text-gray"
                                >Back to Jobs</span></a
                            >
                        </div>
                    </div>
                    {<div class="col-xl-9 col-lg-11 mb-8 px-xxl-15 px-xl-0">
                        <div class="bg-white rounded-4 border border-mercury shadow-9">
                            <div
                                class="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5 pb-8 border-bottom border-width-1 border-default-color light-mode-texts"
                            >
                                <AlertComp />
                                <div class="row">
                                    <div class="col-12">
                                        <div class="media align-items-center">

                                            <div>
                                                <h3 class="font-size-6 mb-0 text-center"> Application for {data.title}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts"
                            >

                                {Object.values(data).length > 0 && <ApplicationForm callback={(e) => { setForm(e); setopen(true) }} />}
                                <AlertComp />
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>}
            {form && <Modal
                style={{ top: 20, }}
                open={open}
                onCancel={() => setopen(false)}
                footer={() => <div className='d-flex justify-content-between px-3'><a
                    class="btn btn-outline-mercury text-black-2 text-uppercase h-px-48 rounded-3 mb-5 px-5"
                    href="#"
                    onClick={(e) => { e.preventDefault(); setopen(false) }}
                >
                    Cancel</a><a
                        onClick={(e) => { e.preventDefault(); apply() }}
                        class="btn btn-green text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5"
                        href="#"
                    >Apply</a></div>}
            >
                < Applicationpreview title={data?.title} data={form} />
            </Modal>}
        </>

    )
}
